import { sendHttpRequest } from '../lib/Utils';
import { store } from '../redux/store';

const {
  REACT_APP_API_EXTERNAL_URL_PIXELS: API_EXTERNAL_URL_PIXELS,
} = process.env;

export const createPixel = async(kwargs) => {
  const { pixel } = kwargs;

  // const { auth } = store.getState();
  // const { user } = auth;
  // const { key: user_key, id: user_id } = user;

  const response = await sendHttpRequest.request({
    url: `${API_EXTERNAL_URL_PIXELS}`,
    method: 'post',
    data: pixel,
    // params: {
    //   user_id,
    //   user_key,
    // },
  });
  return response;
};

export const getPixels = async () => {

  // const { auth } = store.getState();
  // const { user } = auth;
  // const { key: user_key, id: user_id } = user;

  const response = await sendHttpRequest.request({
    url: `${API_EXTERNAL_URL_PIXELS}`,
    method: 'get',
    // params: {
    //   user_id,
    //   user_key,
    // },
  });
  return response;
};

export const getPixel = async (kwargs) => {

  // const { auth } = store.getState();
  // const { user } = auth;
  // const { key: user_key, id: user_id } = user;

  const { pixel_key } = kwargs;
  const response = await sendHttpRequest.request({
    url: `${API_EXTERNAL_URL_PIXELS}/${pixel_key}`,
    method: 'get',
    // params: {
    //   user_id,
    //   user_key,
    // },
  });
  return response;
};

export const getPixelVisits = async (kwargs) => {

  const { pixel_key } = kwargs;
  const response = await sendHttpRequest.request({
    url: `${API_EXTERNAL_URL_PIXELS}/${pixel_key}/visits`,
    method: 'get',
    params: {
      // records: 1000,
      masked: false,
      // order_by_date: 'asc',
      // user_id,
      // user_key,
    },
  });
  return response;
};

export const updatePixel = async (kwargs) => {
  const { pixel_key, pixel } = kwargs;

  const response = await sendHttpRequest.request({
    url: `${API_EXTERNAL_URL_PIXELS}/${pixel_key}`,
    method: 'put',
    data: pixel,
    // params: {
    //   user_id,
    //   user_key,
    // },
  });

  // const response = await sendHttpRequest.put(`/users/${user_key}`, {
  //   data: {
  //     user: {
  //       config: config,
  //     },
  //   }
  // });
  return response;
};

// const { user_key, upload } = kwargs;

// const response = await sendHttpRequest.post('/uploads',
//   { data: { upload } },
//   { params: { user_key } },
// );


export const deletePixel = async (kwargs) => {

  // const { auth } = store.getState();
  // const { user } = auth;

  const { pixel_key } = kwargs;
  const response = await sendHttpRequest.request({
    url: `${API_EXTERNAL_URL_PIXELS}/${pixel_key}`,
    method: 'delete',
    // params: {
    //   user_id,
    //   user_key,
    // },
  });
  return response;
};
