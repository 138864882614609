import React, { forwardRef, useEffect, useState } from "react";
import moment from "moment";
import {
  Box,
  Typography,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  IconButton,
  Button,
  Popper,
  Fade,
  Paper,
  TextField,
  InputAdornment
} from "@mui/material";
import {
  CalendarMonth as CalendarMonthIcon,
 } from '@mui/icons-material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

const defaultIntervals = {
  today: {
    title: "Today",
    startDate: new Date(),
    endDate: new Date()
  },
  yesterday: {
    title: "Yesterday",
    startDate: moment().subtract("1", "day").toDate(),
    endDate: moment().subtract("1", "day").toDate()
  },
  last7: {
    title: "Last 7 days",
    startDate: moment().subtract("7", "days").toDate(),
    endDate: new Date()
  },
  last30: {
    title: "Last 30 days",
    startDate: moment().subtract("30", "days").toDate(),
    endDate: new Date()
  },
  thisMonth: {
    title: "This month",
    startDate: moment().startOf("month").toDate(),
    endDate: moment().endOf("month").toDate()
  },
  prevMonth: {
    title: "Previous month",
    startDate: moment().subtract(1, "month").startOf("month").toDate(),
    endDate: moment().subtract(1, "month").endOf("month").toDate()
  }
};

const minDate = moment().subtract("3", "month").toDate();
const maxDate = new Date();

const defaultStartDate = moment().subtract("7", "day").toDate();
const defaultEndDate = new Date();

export default function BdexDatePicker(kwargs) {
  
  const [dateRange, setDateRange] = useState([defaultStartDate, defaultEndDate]);
  const [startDate, endDate] = dateRange;
  
  const ExampleCustomInput = forwardRef(
    ({ value, onClick }, ref) => (
      // <CalendarMonthIcon color="primary" sx={{ fontSize: "small" }}>
      <Button
        type='submit'
        // variant='outlined'
        color='primary'
        onClick={onClick}
        startIcon={<CalendarMonthIcon color="primary" sx={{ fontSize: 'small' }} />}
        sx={{ fontSize: 'small' }}//, justifyContent: 'flex-start', alignItems: 'center' }}
        ref={ref}
      >
        {value}
      </Button>
      // </CalendarMonthIcon>
    ),
  );

  return (
    <DatePicker
      selectsRange={true}
      minDate={minDate}
      maxDate={maxDate}
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        setDateRange(update);
      }}
      customInput={<ExampleCustomInput />}
      // customTimeInput={}
      // isClearable={false}
      onCalendarClose={() => {
        if (!endDate && startDate) {
          setDateRange([startDate, defaultEndDate]);
        }
      }}
      highlightDates={[minDate, maxDate]}
      monthsShown={2}
      // inline
      showPopperArrow={false}
      withPortal
      todayButton="Today"
    />
  );
};
