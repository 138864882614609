import { dataGridColumns } from "../../../lib/Utils";

export const pixelVisitsSchema = [
  {
    field: "last_date_of_visit",
    headerName: "date_visited",
    editable: false,
    sortable: true,
    hide: false,
    flex: 120,
  },
  {
    field: "first_name",
    headerName: "first_name",
    editable: false,
    sortable: true,
    hide: false,
    flex: 80,
  },
  {
    field: "last_name",
    headerName: "last_name",
    editable: false,
    sortable: true,
    hide: false,
    flex: 80,
  },
  {
    field: "address1",
    headerName: "address1",
    editable: false,
    sortable: true,
    hide: false,
    flex: 120,
  },
  {
    field: "address2",
    headerName: "address2",
    editable: false,
    sortable: true,
    hide: false,
    flex: 60,
  },
  {
    field: "city",
    headerName: "city",
    editable: false,
    sortable: true,
    hide: false,
    flex: 80,
  },
  {
    field: "state",
    headerName: "state",
    editable: false,
    sortable: true,
    hide: false,
    flex: 20,
  },
  {
    field: "postal_code",
    headerName: "postal_code",
    editable: false,
    sortable: true,
    hide: false,
    flex: 50,
  },
  {
    field: "email_address",
    headerName: "email_address",
    editable: false,
    sortable: true,
    hide: false,
    flex: 80,
  },
  {
    field: "ip_address",
    headerName: "ip_address",
    editable: false,
    sortable: true,
    hide: false,
    flex: 70,
  },
  {
    field: "phone_1",
    headerName: "phone_1",
    editable: false,
    sortable: true,
    hide: true,
    flex: 80,
    ...dataGridColumns.display_phone,
  },
  {
    field: "phone_2",
    headerName: "phone_2",
    editable: false,
    sortable: true,
    hide: true,
    flex: 80,
    ...dataGridColumns.display_phone,
  },
  {
    field: "phone_3",
    headerName: "phone_3",
    editable: false,
    sortable: true,
    hide: true,
    flex: 80,
    ...dataGridColumns.display_phone,
  },
];
