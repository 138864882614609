/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Tooltip, Typography, Stack } from '@mui/material';
import {
  Refresh as RefreshIcon,
  Autorenew as AutorenewIcon,
} from '@mui/icons-material';

export default function AutoRefresh(props) {

  const { handler, off, disabled } = props;
  
  const {
    REACT_APP_AUTO_REFRESH_ENABLED,
    REACT_APP_AUTO_REFRESH_INTERVAL_MS: AUTO_REFRESH_INTERVAL_MS,
  } = process.env;  
  const AUTO_REFRESH_ENABLED = disabled ? false : REACT_APP_AUTO_REFRESH_ENABLED;

  const [autoRefreshInterval, setAutoRefreshInterval] = useState();

  const handleToggleAutoRefresh = () => {
    if (!autoRefreshInterval) setAutoRefreshInterval(AUTO_REFRESH_INTERVAL_MS);
    else setAutoRefreshInterval(0);
  };

  // initial load
  useEffect(() => {
    if (AUTO_REFRESH_ENABLED && !off) setAutoRefreshInterval(AUTO_REFRESH_INTERVAL_MS);
  }, []);

  // autorefresh
  useEffect(() => {
    if (autoRefreshInterval) {
      const interval = setInterval(handler, autoRefreshInterval);
      return () => clearInterval(interval);
    }
  }, [autoRefreshInterval]);

  return (
    <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="stretch">
      { AUTO_REFRESH_ENABLED ?
        <>
          <Tooltip title={`toggle auto refresh (${AUTO_REFRESH_INTERVAL_MS/1000}s)`} arrow>
            <AutorenewIcon
              fontSize="small"
              color="action"
              onClick={handleToggleAutoRefresh}
            />
          </Tooltip>
          <Typography variant="caption" display="block" gutterBottom>
            {'auto refresh'}
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
          <strong>{autoRefreshInterval ? 'on': 'off'}</strong>
          </Typography>
        </>
        : null
      }
      <Tooltip title="refresh manually" arrow>
        <RefreshIcon
          fontSize="small"
          color="action"
          onClick={handler}
        />
      </Tooltip>
    </Stack>
  )
};
